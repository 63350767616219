import React from 'react'
import {
    AwesomeButton,
  } from 'react-awesome-button'
import  'react-awesome-button/dist/themes/theme-rickiest.css'

function Header() {
    return (
      <div>
        <div className="center-div mt100">
          <AwesomeButton disabled type="primary" className="h70 mt5 mr5 text30 ml-50 animrotate-5 darkred">T</AwesomeButton>
          <AwesomeButton disabled type="primary" className="h70 mt20 mr5 text30 animrotate5 darkred">R</AwesomeButton>
          <AwesomeButton disabled type="primary" className="h70 mt-5 mr5 text30 animrotate-5 darkred">I</AwesomeButton>
          <AwesomeButton disabled type="primary" className="h70 mt-10 mr5 text30 animrotate5 darkred">V</AwesomeButton>
          <span className="app-name-5">5</span>
          <br className="clear-fix" />
        </div>
      </div>
    )
}

export default Header
