import React, { Component } from 'react'
import {
  AwesomeButton,
} from 'react-awesome-button';
import Header from './Header';
import  'react-awesome-button/dist/themes/theme-rickiest.css';
import Variables from './Variables';
import LimitReachedPopup from "./LimitReachedPopup";
import Footer from './Footer';
import cookie from 'react-cookies'
import Greeny from './Greeny';

export class Start extends Component {

  StartPlaying = (e) => {
    this.props.onChange(2);
  } 
  state = {
    processing: false
  }
  componentDidMount() {
    this.setState({
      processing: true
    });
    Variables.mobileNo = cookie.load('bw_user');
    if(typeof(Variables.mobileNo) === "undefined"){
      Variables.mobileNo = "";
    }
    fetch(Variables.apiBaseUrl + 'getAllocation', {
        mode: 'cors'
      }).then(results => {
        return results.json();
      }).then(data => {
        
        Variables.creditLimit = ((data.data.credit_limit - data.data.credit_used)*500).toLocaleString(navigator.language, { minimumFractionDigits: 0 });
        Variables.creditUsed = data.data.credit_used;
        Variables.dataLimit = ((data.data.data_limit - data.data.data_used)*500).toLocaleString(navigator.language, { minimumFractionDigits: 0 });
        Variables.dataUsed = data.data.data_used;
        setTimeout(() => {
            this.setState({
                processing: false
            });
        }, 200);
      })
  }

  render() {
    return (
      <div className="fadeinanime">
        <Greeny />
        <Header />
        <h2 className="center-div mt50 white max400">
          5 questions in 50secs for up to Ghs5 credit instantly <span role="img" aria-label="All the best">😉</span>
        </h2>
        <div className="center-div">
          <AwesomeButton className="startPlaying" type="secondary" action={this.StartPlaying}>Let's go!</AwesomeButton>
        </div>
        <div className="center-div mt10">
          <a className="t_and_c" 
            href="https://brainwavegh.com/terms-conditions.html">Terms & Conditions apply*</a>
        </div>
        <div className="clear-fix center-div mt20 limitNoticeContainer table">
          <div className="header">
            <h2 className="white">Available Credit</h2>
          </div>
          
          {this.state.processing && 
          <>
            <div className="lds-spinner small"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </>
          }
          {!this.state.processing && 
          <div className="float-right item ml10 animrotate5">
            <>
              <h2 className="mt0 mb0">Ghs{Variables.creditLimit}</h2>
            </>
          </div>
          }
        </div>

        {this.state.popup === 3 && 
        <LimitReachedPopup onChange={this.ChangePopup} />
        }
        <Footer />
      </div>
    )
  }
}

export default Start
