import React, { Component } from 'react'
import '../css/modal/Component.css'
  import  'react-awesome-button/dist/themes/theme-rickiest.css';
  import Greeny from './Greeny';

export class LimitReachedPopup extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            showModal: true
        };
    }
    ClosePopup = () => {
        this.setState({
            showModal: false
        })
    }
  render() {
    return (
        <div>
            <Greeny />
          <div className={(this.state.showModal)? "md-modal md-effect-8 md-show" : "md-modal md-effect-8"}
                      id="subscribeModal">
                  <div className="md-content">
                      <div>
                          <p>You've reached your limit for today. Check back tomorrow for more goodies 
                              <span role="img" aria-label="All the best">😉</span>
                          </p>
                          <div className="center-div mt20 option">
                              <span className="cur-hand" onClick={this.ClosePopup}>Close</span>
  
                          </div>
                      </div>
                  </div>
              </div>
              <div className="md-overlay"></div>
        </div>
      )
  }
}

export default LimitReachedPopup
