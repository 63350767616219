import React, { Component } from 'react'
import Variables from "./Variables";
import Header from './Header';
import Greeny from './Greeny';
import { Trans  } from 'react-i18next';

export class CountDown extends Component {
  variables = Variables;
  imgUrls = [];
  imgDownloadingIndex = 0;
    
  constructor(props) {
    super(props);
  
    this.state = {
      timeLeft: 4,
      questionDownloaded: false,
      questionImage: "",
      noquestion: 0
    };

    // This binding is necessary to make `this` work in the callback
    this.startCountDown = this.startCountDown.bind(this);
    this.beginCountDown = this.beginCountDown.bind(this);
    this.startPlaying = this.startPlaying.bind(this);
  }
  
  startPlaying(e) {
      clearInterval(this.intervalHandle);
      this.props.onChange(4);
  } 

  beginCountDown() {
      let timeLeft = this.state.timeLeft - 1;
      if(timeLeft <= 0){
          this.startPlaying();
          return;
      }
      this.setState({timeLeft: timeLeft});
  }

  startCountDown() {
        this.setState({
          questionDownloaded: true
        });
        this.intervalHandle = setInterval(this.beginCountDown, 1000);
  }

  downloadQuestion(){
    fetch(`${Variables.apiBaseUrl}getTriv5Questions/${Variables.mobileNo}/${Variables.globalToken}/${Variables.token}/${Variables.questionLimit}`, {
      mode: 'cors',
      headers: {
        'Authorization': Variables.authorization,
      },
    }).then(results => {
      return results.text();
    }).then(data => {
      if(data.search("error") >= 0){
        this.setState({
          noquestion: 1
        })
        this.postErrorMsgToSlack(Variables.mobileNo, Error("No more question"), data)
        return;
      }

      try{
        var xdata = JSON.parse(this.handShake(data));
        Variables.questions = xdata.data;
        Variables.questionIndex = 0;
        this.downloadImages();
      }catch(err) {
        this.postErrorMsgToSlack(Variables.mobileNo, err, data)
      }
    })
  }

  postErrorMsgToSlack(player, err, data){
    var myHeaders = new Headers();
    var errorMsg = `
======== Triv5 Failed ========
Player: ${player}
Error: ${err.message}
Server Data: ${data}
==============================
    `;
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var raw = JSON.stringify({"text":errorMsg});
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(Variables.slackBotApi, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  handShake(data){
    var xdata = data.split(".")
    var xxdata = atob(xdata[0]);
    var xhx = xdata[1].split('').reverse().join('');
    var h = atob(xhx);

    var content = this.whatgoesaroundcomesaround(xxdata, h);
    return content;
  }

  whatgoesaroundcomesaround (s, k) {
    var enc = "";
    var str = s.toString();
    for (var i = 0; i < str.length; i++) {
      var a = str.charCodeAt(i);
      var b = a ^ k;
      enc = enc + String.fromCharCode(b);
    }
    return enc;
  }

  downloadImages() {
      for (let d of Variables.questions) {
        let question = d.question;
        var matches = question.match(/\bhttps?:\/\/\S+/gi);
        matches = (matches == null) ? [] : matches;
        if(matches.length > 0){
          let imgurl = matches[0];
          this.imgUrls.push(imgurl);
        }
      }

      if(this.imgUrls.length > 0){
        this.downloadImage(this.imgUrls[0])
      }else{
        this.startCountDown();
      }
  }

  downloadImage(url){
      this.setState({
        questionImage: url
      })
  }

  handleImageLoaded = (e) => {
      this.imgDownloadingIndex += 1
      if(this.imgDownloadingIndex >= this.imgUrls.length){
        this.startCountDown();
        return;
      }
      this.downloadImage(this.imgUrls[this.imgDownloadingIndex]);
  }

  handleImageErrored = (e) => {
      this.imgDownloadingIndex += 1
      if(this.imgDownloadingIndex >= this.imgUrls.length){
        this.startCountDown();
        return;
      }
      this.downloadImage(this.imgUrls[this.imgDownloadingIndex]);
  }

  componentDidMount(){
    this.downloadQuestion();
  }

  render() {
    if(!this.state.questionDownloaded)
    return(
    <div className="fadeinanime">
      <Greeny />
      <Header />
      <div className="center-div mt100">
      
      {this.state.noquestion === 0 && 
      <>
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <h3 className="white mt20">
          <Trans>Downloading questions</Trans>
        </h3>
        {this.state.questionImage.length > 0 && 
        <img alt="" width="1" height="1"
            src={this.state.questionImage}
            onLoad={this.handleImageLoaded}
            onError={this.handleImageErrored}
          />
        }
      </>
      }

      {this.state.noquestion === 1 && 
      <div className="center-div mt40 max400">
        <img src="giphy.webp" className="full-width" alt="" />
        <h2 className="white mt20">
          Creating new questions just for you. Come back later
        </h2>
      </div>
      }
      </div>
    </div>
    )

    return (
      <div className="center-div">
        <h1 className="text200 white">{this.state.timeLeft}</h1>
        <h1 className="white">
          <Trans>Ready</Trans>
        </h1>
      </div>
    )
  }
}

export default CountDown
