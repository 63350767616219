import React, { Component } from 'react'
import '../css/modal/Component.css'
import {
    AwesomeButton,
  } from 'react-awesome-button';
  import  'react-awesome-button/dist/themes/theme-rickiest.css';
import { setTimeout } from 'timers';
import Variables from "./Variables";

export class PopupSubscribe extends Component {
    variables = Variables;
    constructor(props){
        super(props);
        this.state = {
            subscriptionSent: false,
            showModal: true,
            processing: false,
            SelectNetwork: false,
            otpstate: 0,
            otperror: ""
        };
    }
    
    ClosePopup = () => {
        this.setState({
            showModal: false
        })

        setTimeout(() => {
            if(this.state.subscriptionSent === false){
                this.props.onChange(0)
                return;
            }
            if(Variables.network === "mtn"){
                this.props.onChange(2)
            }else{
                this.props.onChange(0)
            }
        }, 1000);
    }

    SendOTP(mobileNo){
        var url = Variables.apiBaseUrl + 'initiateOTP/' + mobileNo

        fetch(url, {mode: 'cors'})
            .then(results => {
            return results.json();
            }).then(data => {
                this.setState({
                    processing: false
                })
                if(data.hasOwnProperty('success')){
                    this.HideSelectNetwork()
                    this.setState({
                        otpstate: 1
                    });
                }
            })
    }

    VerifyOTP(mobileNo, otpCode) {
        var url = Variables.apiBaseUrl + 'verifyOTP'

        this.setState({
            otperror: ""
        });
        let data = {
            mobileNo: mobileNo,
            otpCode: otpCode,
            network: Variables.network,
            contractId: Variables.contractId,
        };
        fetch(url, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(data),
            }).then(results => {
                return results.json();
            }).then(data => {
                this.setState({
                    processing: false
                })
                if(data.hasOwnProperty('success')){
                    this.setState({
                        otpstate: 2
                    })
                    this.SubscribeMobileNo()
                }else{
                    this.setState({
                        otperror: data.error
                    });
                }
            })
    }

    SubscribeMobileNo = () => {
        this.setState({
            processing: true
        })
        
        var mobileNo = '%2b' + Variables.countryCode + Variables.mobileNo.substr(Variables.mobileNo.length - 9);
        var network = Variables.network;
        
        if(this.state.otpstate === 0){
            this.SendOTP(mobileNo)
            return;
        }
        else if(this.state.otpstate === 1){
            let otpCode = document.getElementById("txOtpcode").value;
            if(otpCode.length >= 4){
                this.VerifyOTP(mobileNo, otpCode)
            }else{
                this.setState({
                    processing: false
                })
            }
            return;
        }else{
            var return_url = window.location.href;
            var url = Variables.subscriptionApiBase + 'subscribe/' + network + '/' + mobileNo + "/triv5?return=" + return_url

            fetch(url, {mode: 'cors'})
                .then(results => {
                return results.json();
                }).then(data => {
                    var mobileNo = Variables.countryCode + Variables.mobileNo.substr(Variables.mobileNo.length - 9);
                    window.location.href = window.location.href = window.location.href + "?MSISDN=" + mobileNo
                })
        }
    }

    SelectNetwork = () => {
        if(Variables.networks.length > 1){
            this.setState({
                SelectNetwork: true
            })
        }else{
            Variables.network = Variables.networks[0].toLowerCase();
            this.SubscribeMobileNo()
        }
    }

    HideInitOptIn = (e) =>{
        e.target.classList.add("hide");
        let ele = document.getElementById("select-network-div")
        ele.classList.remove("hide");
        ele.classList.add("fadeinanime");
    }

    HideSelectNetwork(){
        let ele = document.getElementById("select-network-div")
        ele.classList.add("hide");

        ele = document.getElementById("optin-div")
        ele.classList.add("hide");

        ele = document.getElementById("enter-otp-div")
        ele.classList.remove("hide");
    }

    SetNetwork = (e) => {
        let network = e.target.innerText.trim();
        Variables.network = network.toLowerCase();
        let networks = document.getElementsByClassName("select-network");
        for (let item of networks) {
            item.classList.remove("selected");
        }
        e.target.classList.add("selected");

        let dvcontinue = document.getElementById("continue-div")
        dvcontinue.classList.remove("hide");
        dvcontinue.classList.add("fadeinanime");
    }

  render() {
    return (
      <div>
        <div className={(this.state.showModal)? "md-modal md-effect-8 md-show" : "md-modal md-effect-8"}
            id="subscribeModal">
                <div className="md-content">
                    <div id="optin-div" className={this.state.SelectNetwork === true ? "fadeoutanime" : ""} onAnimationEnd={this.HideInitOptIn}>
                        <p><b>{Variables.mobileNo}</b> is currently not subscribed to {Variables.serviceName} service. Tap the Opt In button to join.</p>
                        <div className="center-div mt20 option">
                            <span className="cur-hand" onClick={this.ClosePopup}>Not Now</span>
                            {this.state.processing &&
                                <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary" disabled >Procesing...</AwesomeButton>
                            }
                            {!this.state.processing &&
                                <AwesomeButton className="aws-btn__content ml10 inline-btn" 
                                    type="secondary" action={this.SelectNetwork}>Opt In</AwesomeButton>
                            }

                        </div>
                    </div>
                    <div id="select-network-div" className="hide">
                        <p className="center-div white mt20"><b>Select network for the number {Variables.mobileNo}</b></p>
                        <div className="center-div clear-fix table mt10">
                            {Variables.networks.map((n) => (
                                <div key={n} className="cur-hand table select-network float-left" 
                                    onClick={this.SetNetwork}>{n}</div>
                            ))
                            }
                        </div>

                        <div className="center-div mt25 option hide" id="continue-div">
                        {!this.state.processing &&
                            <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary" 
                                action={this.SubscribeMobileNo}>Continue</AwesomeButton>
                        }
                        {this.state.processing &&
                            <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary" disabled >Procesing...</AwesomeButton>
                        }

                        </div>

                    </div>
                    <div id="enter-otp-div" className="hide">
                        <p className="center-div white mt20"><b>Enter the pin sent to {Variables.mobileNo} below</b></p>
                        <div className="center-div clear-fix table mt10">
                            <input type="text" id="txOtpcode" className="text20 text-center full-width" />
                        </div>

                        {this.state.otperror.length > 0 && 
                            <p className="center-div grey">{this.state.otperror}</p>
                        }
                        <div className="center-div mt25 option" id="continue-div">
                        {!this.state.processing &&
                            <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary" 
                                action={this.SubscribeMobileNo}>Continue</AwesomeButton>
                        }
                        {this.state.processing &&
                            <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary" disabled >Verifying PIN...</AwesomeButton>
                        }

                        </div>
                    </div>
                </div>
            </div>
            <div className="md-overlay"></div>
      </div>
    )
  }
}

export default PopupSubscribe
