import React, { Component } from 'react'
import Processing from "./Processing";
import Variables from "./Variables";
import cookie from 'react-cookies'

export class Captcha extends Component {

    constructor(props){
        super(props)
        this.state = {
            processing: true
        }
    }

    componentWillMount(){
        var data = {
            answer: Variables.selectedAns,
            prime: Variables.timeUsed,
            token: Variables.token,
            globalToken: Variables.globalToken,
            mobileNo: Variables.mobileNo,
        }
        cookie.save('_bw_ref', JSON.stringify(data), { path: '/' })
        this.submitAnswer()
    }

    submitAnswer(){
        let mthis = this
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6LcK7-4UAAAAAACQE3fh_-diHJnzXJDcbvbxVn-N', {action: "triv5"})
            .then(function(token) {
                Variables.savitar = token
                mthis.completeRequest()
            })
        })
    }

    completeRequest(){
        let timeUsed = Variables.timeUsed
        let selectedAns = Variables.selectedAns
        selectedAns["savitar"] = Variables.savitar
        selectedAns["friend"] = Variables.friendsNumber
        fetch(Variables.apiBaseUrl + 'validateTriv5Answers/' + Variables.mobileNo + "/" + Variables.globalToken + "/" + Variables.token + "/" + timeUsed, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Authorization': Variables.authorization,
            },
            body: JSON.stringify(selectedAns),
            
        }).then(results => {
            return results.json();
        }).then(data => {
            setTimeout(() => {
              this.setState({
                  processing: false
              });
            }, 1000);
            if(data.hasOwnProperty('success')){
              let dataWon = data.data.data_topup;
              let creditWon = data.data.credit_topup;
              Variables.pointWon = data.data.points;
              Variables.totalBrainwavePoint = data.data.total_points;
  
              Variables.dataWon = (dataWon == null) ? creditWon + " crédito" : dataWon + " data";
              Variables.timeTaken = timeUsed;
              this.props.onChange(6);
            }else{
                //regenerate token
                this.props.onChange(9);
            }
        }).catch((err) => {
            this.props.onChange(9);
        })
    }

    render() {
        return (
            <Processing visible={this.state.processing} />
        )
    }
}

export default Captcha
