// Libraries
import React, { Component } from 'react';

// Components
import Billme from './ncomponents/Billme';
import Captcha from './ncomponents/Captcha';
import cookie from 'react-cookies'
import CountDown from './ncomponents/CountDown';
import EnterFriendsNumber from './ncomponents/EnterFriendsNumber';
import EnterMobileNo from './ncomponents/EnterMobileNo';
import InvalidCaptcha from './ncomponents/InvalidCaptcha';
import Processing from './ncomponents/Processing';
import Question from './ncomponents/Question';
import Start from './ncomponents/Start';
import YouLost from './ncomponents/YouLost';
import YouWon from './ncomponents/YouWon';
import { BW_URL } from './utils/constants'

// Utils
import Variables from "./ncomponents/Variables";

// Styles
import './App.css';

import { withTranslation } from 'react-i18next';

class App extends Component {
  variables = Variables;

  constructor(props) {
    super(props);
    
    this.state = {
      begin: 7,
      banner: false,
      bannerLoaded: false
    };
  }

  async componentWillMount() { 
    Variables.mobileNo = this.getParameterByName("MSISDN")
    Variables.globalToken = this.getParameterByName("TOKEN")
    Variables.globalLanguage = this.getParameterByName("lng")
    this.props.i18n.changeLanguage(Variables.globalLanguage)

    //if mobileno is a valid mobileno format, redirect player to play.brainwave
    if(!Variables.mobileNo || isNaN(Variables.mobileNo.trim()) ){
      window.location.href = BW_URL;
      return;
    }

    //init and set google captcher token
    window.grecaptcha.ready(function() {
      window.grecaptcha.execute('6LcK7-4UAAAAAACQE3fh_-diHJnzXJDcbvbxVn-N', {action: "triv5"})
      .then(function(token) {
        Variables.savitar = token
      })
    })

    if (Variables.mobileNo !== null && Variables.globalToken !== null) { 
      await this.getFriends();
      await this.getBanner();
    } else { 
      window.location.href = BW_URL;
    }
  }

  getFriends = async() => {
    const response = await fetch(`${Variables.apiBaseUrl}getFriends/${Variables.mobileNo}/${Variables.globalToken}`, {
      mode: 'cors',
      headers: {
        'Authorization': Variables.authorization,
      },
    });

    const data = await response.json();
    if (data) {
      Variables.doubleWinAlreadySharedWith = data;
    }
  }

  getBanner = async() => {
    const response = await fetch(`${Variables.apiBaseUrl}getMainImage`, {
      mode: 'cors',
      headers: {
        'Authorization': Variables.authorization,
      },
    });

    const data = await response.json();
    const imageUrl = data.image_url;

    // download image
    if (imageUrl) { 
      await this.downloadAndShowBanner(imageUrl);
      if (Variables.isDoubleWin) {
        this.setState({
          begin: 11,
        });
      }
    } else {
      if (Variables.isDoubleWin) {
        this.setState({
          begin: 11,
        });
      }else {
        await this.generateToken();
      }
    }
  }

  downloadAndShowBanner = async(imageUrl) => {
    this.setState({
      banner: imageUrl,
    });
  }

  generateToken = async() => { 
    this.setState({
      bannerLoaded: false,
      banner: false,
      begin: 7
    })
    const response = await fetch(`${Variables.apiBaseUrl}triv5GenToken/${Variables.mobileNo}/${Variables.globalToken}`, {
      mode: 'cors',
      headers: {
        'Authorization': Variables.authorization,
      },
    });

    const data = await response.json();
    if (data.status === 'error') {
      // TODO: redirect back to brainwave
      // window.location.href(BW_URL);
    }

    if (data.hasOwnProperty('success')) { 
      Variables.token = data.token;
      Variables.playCount = parseInt(data.lives);
      Variables.maxPlayCount = parseInt(data.max_lives);
      Variables.maxPlayTrial = parseInt(data.limit);
      Variables.timeRewardPair = data.time_reward_pair;
      this.setState({
        begin: 3
      });
    } else { 
      Variables.playCount = 0;
      Variables.maxPlayCount = 0;
      if(data.hasOwnProperty('max_lives')){
        Variables.playCount = parseInt(data.lives);
        Variables.maxPlayCount = parseInt(data.max_lives);
        Variables.maxPlayTrial = parseInt(data.limit);
      }

      this.setState({
        begin: 10
      });
    }
  }

  hasPendingReward(){
    var bwRef = cookie.load('_bw_ref');
    if(bwRef !== undefined) {
      Variables.selectedAns = bwRef.answer
      Variables.timeUsed = bwRef.prime
      Variables.token = (Variables.token === "") ? bwRef.token : Variables.token;
      Variables.globalToken = bwRef.globalToken;
      Variables.mobileNo = bwRef.mobileNo;
      this.setState({
        begin: 8
      });
      return true;
    }
    return false;
  }

  isPrivateMode() {
    return new Promise((resolve) => {
      const yes = () => resolve(true); // is in private mode
      const not = () => resolve(false); // not in private mode
      const testLocalStorage = () => {
        try {
          if (localStorage.length) not();
          else {
            localStorage.x = 1;
            localStorage.removeItem('x');
            not();
          }
        } catch (e) {
          // Safari only enables cookie in private mode
          // if cookie is disabled, then all client side storage is disabled
          // if all client side storage is disabled, then there is no point
          // in using private mode
          navigator.cookieEnabled ? yes() : not();
        }
      };
      // Chrome & Opera
      if (window.webkitRequestFileSystem) {
        return void window.webkitRequestFileSystem(0, 0, not, yes);
      }
      // Firefox
      if ('MozAppearance' in document.documentElement.style) {
        if (indexedDB === null) return yes();
        const db = indexedDB.open('test');
        db.onerror = yes;
        db.onsuccess = not;
        return void 0;
      }
      // Safari
      const isSafari = navigator.userAgent.match(/Version\/([0-9._]+).*Safari/);
      if (isSafari) {
        const version = parseInt(isSafari[1], 10);
        if (version < 11) return testLocalStorage();
        try {
          window.openDatabase(null, null, null, null);
          return not();
        } catch (_) {
          return yes();
        };
      }
      // IE10+ & Edge InPrivate
      if (!window.indexedDB && (window.PointerEvent || window.MSPointerEvent)) {
        return yes();
      }
      // default navigation mode
      return not();
    });
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  changeHandler = (value) => {
    this.setState({
        begin: value
    });
  }

  handleBannerLoaded = (e) => {
    // image loaded
    this.setState({
      bannerLoaded: true
    });
  }

  handleBannerErrored = (e) => {
    // failed to download
  }

  getComponent = (position) => {
    let component = <div></div>;

    switch(position) {
      case 1:
        component =  <Start onChange={this.changeHandler}  />
        break
      case 2:
        component =  <EnterMobileNo onChange={this.changeHandler} />
        break
      case 3:
        component =  <CountDown onChange={this.changeHandler} />
        break
      case 4:
        component =  <Question onChange={this.changeHandler} />
        break
      case 5:
        component =  <YouLost onChange={this.changeHandler} />
        break
      case 6:
        component =  <YouWon onChange={this.changeHandler} />
        break
      case 7:
        component =  
          <>
            { this.state.banner && 
              <div className='text-center middle-container fullH'>
                <img alt='Banner' className='cur-hand banner' onClick={this.generateToken}
                  src={ this.state.banner }
                  width={this.state.bannerLoaded ? 'auto': 1} height={this.state.bannerLoaded ? 'auto': 1}
                  onLoad={this.handleBannerLoaded}
                  onError={this.handleBannerErrored}
                />
              </div>
            }
            { !this.state.bannerLoaded && 
              <Processing visible={true} />
            }
          </>
        break
      case 8:
        component =  <Captcha onChange={this.changeHandler} />
        break
      case 9:
        component =  <InvalidCaptcha />
        break
      case 10:
        component =  <Billme />
        break
      case 11:
        component =  <>
            { this.state.banner ?
              <div className='text-center middle-container fullH'>
                <img alt='Banner' className='banner absolute behind'
                  src={ this.state.banner }
                  width={this.state.bannerLoaded ? 'auto': 1} height={this.state.bannerLoaded ? 'auto': 1}
                  onLoad={this.handleBannerLoaded}
                />
                <EnterFriendsNumber onContinue={ this.generateToken } />
              </div>
              : <EnterFriendsNumber onContinue={ this.generateToken } />
            }
          </>
        break
      default: 
        component =  <div/>
        break
    }

    return component;
  }
  
  render() {
    const begin = this.state.begin;
    return (
      <div className="App">
        { this.getComponent(begin) }
      </div>
    );
  }
}

export default withTranslation()(App);
