import React from 'react';
import Variables from './Variables';

const numRegex = /^[+-]?\d*(?:[.,]\d*)?$/;
function EnterFriendsNumber({ onContinue }) {
  const [number, setNumber] = React.useState('');
  const [error, setError] = React.useState('');

  React.useEffect(() => { 
    const mobile = number.startsWith("0") ? number.substring(1) : number;
    if (mobile.length === 9 && Variables.mobileNo.endsWith(mobile)) {
      setError(Variables.doubleWinError1);
      setNumber('');
    }

    if(mobile.length === 9 && Variables.doubleWinAlreadySharedWith.find(n => n === `+233${mobile}`)) {
      setError(Variables.doubleWinError2);
      setNumber('');
    }
  }, [number]);

  const handleChange = (e) => { 
    if (numRegex.test(e.target.value)) {
      setNumber(e.target.value);
      setError('');
    }
  }

  const saveFriendsNumber = () => {
    if (number.length < 9 && number.length > 0)
      return;
    
    Variables.friendsNumber = number;
    onContinue();
  }

  return <div className='friend-number-container text-center'>
    <p className='white text16 pr25 pl25'>{Variables.doubleWinText1}</p>
    <p className='white text14 pr25 pl25'>{Variables.doubleWinText2}</p>
    <p className='mt20'>
      <input type='text' placeholder={Variables.doubleWinText3}
        pattern='[+-]?\d+(?:[.,]\d+)?'
        maxLength={10} value={ number } onChange={ handleChange } />
    </p>
    { error && 
      <p class='error-msg'>{error}</p>
    }
    <div className="startPlaying option" onClick={ saveFriendsNumber }>
      Play Now
    </div>
  </div>;
}

export default EnterFriendsNumber;
