import React, { Component } from 'react'

export class SubscriptionSent extends Component {

    constructor(props){
        super(props);
        this.state = {
            showModal: true
        };
    }
    ClosePopup = () => {
        this.setState({
            showModal: false
        })

        setTimeout(() => {
            this.props.onChange(0)
        }, 1000);
    }

  render() {
    return (
      <div>
        <div 
            className={(this.state.showModal)? "md-modal md-effect-8 md-show" : "md-modal md-effect-8"}
                    id="subscribeModal">
                <div className="md-content">
                    <div>
                        <p>Follow subscription process on your phone to complete request.</p>
                        <div className="center-div mt20 option">
                            <span className="cur-hand" onClick={this.ClosePopup}>Close</span>

                        </div>
                    </div>
                </div>
            </div>
            <div className="md-overlay"></div>
      </div>
    )
  }
}

export default SubscriptionSent
