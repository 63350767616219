// Libraries
import React, { Component } from 'react'
import i18n from '../i18n';

// Components
import Processing from "./Processing";
import Greeny from './Greeny';

// Utilities
import Variables from "./Variables";

export class Question extends Component {
    constructor(props){
        super(props);

        this.state = {
            timeLeft: Variables.gameTime,
            questionIndex: 0,
            imgQuestion: '',
            textQuestion: '',
            questions: [],
            choices: [],
            dataToGet: 'Ghs5',
            selectedAns: {},
            mobileNo: Variables.mobileNo,
            processing: false,
            canAnswer: false,
          };
    }

    handshakeCheck(){
      var str = this.state.questions[this.state.questionIndex].token.trim()
      var h = this.state.questions[this.state.questionIndex].h
      var xstr = atob(str);

      var content = this.whatgoesaroundcomesaround(xstr, h);
      return content;
    }

    whatgoesaroundcomesaround (s, k) {
      var enc = "";
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        var a = str.charCodeAt(i);
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }

    NextQuestion = (e) => {
      let questionSection = document.getElementById("questionSection");
      questionSection.classList.add("fadeoutanime");
      let selectedAns = e.target.innerText.trim();
      Variables.currentSelection = selectedAns;
      let answerObj = this.state.selectedAns;
      let questionId = this.state.questions[this.state.questionIndex].id;
      answerObj[questionId] = selectedAns;
      Variables.selectedAns = answerObj
      
      this.setState({
        selectedAns: answerObj
      });
      
      
      if(this.handshakeCheck() !== selectedAns+this.state.questions[this.state.questionIndex].question ){
        this.endGame();
        return;
      }
    }

    stopWorker() { 
      Variables.worker.terminate();
      Variables.worker = undefined;
    }

    startWorker() {
      if(typeof(Worker) !== "undefined") {
        if(typeof(Variables.worker) == "undefined") {
          Variables.worker = new Worker("countdown_worker.js");
        }
        Variables.worker.onmessage = ((event) => {
          this.updateCountDown(event.data)
        });
      }
      return false
    }

    endGame() {
        clearInterval(this.intervalHandle);
        this.stopWorker();
        var optionA = this.state.questions[this.state.questionIndex].optionA;
        var optionB = this.state.questions[this.state.questionIndex].optionB;
        var optionC = this.state.questions[this.state.questionIndex].optionC;
        var optionD = this.state.questions[this.state.questionIndex].optionD;
        var question = this.state.questions[this.state.questionIndex].question;

        Variables.question = question;
        if(this.handshakeCheck() === optionA+question){
          Variables.answer = optionA;
        }
        if(this.handshakeCheck() === optionB+question){
          Variables.answer = optionB;
        }
        if(this.handshakeCheck() === optionC+question){
          Variables.answer = optionC;
        }
        if(this.handshakeCheck() === optionD+question){
          Variables.answer = optionD;
        }
        this.submitSelection();

        if(this.state.questionIndex === 0)
          Variables.wrongQuestionPosition = i18n.t("first");
        if(this.state.questionIndex === 1)
          Variables.wrongQuestionPosition = i18n.t("second");
        if(this.state.questionIndex === 2)
          Variables.wrongQuestionPosition = i18n.t("third");
        if(this.state.questionIndex === 3)
          Variables.wrongQuestionPosition = i18n.t("fourth");
        if(this.state.questionIndex === 4)
          Variables.wrongQuestionPosition = i18n.t("fifth");
          
        this.props.onChange(5);
    }

    endGameSuccess(){
      clearInterval(this.intervalHandle);
      this.stopWorker();
      let timeUsed = Variables.gameTime - this.state.timeLeft;
      Variables.timeUsed = timeUsed

      this.props.onChange(8);
    }

    submitSelection(){
      let selectedAns = {
        ...this.state.selectedAns,
        friend: Variables.friendsNumber
      };
      let timeUsed = Variables.gameTime - this.state.timeLeft;
        fetch(Variables.apiBaseUrl + 'validateTriv5Answers/' + Variables.mobileNo + "/" + Variables.globalToken + "/" + Variables.token + "/" + timeUsed, {
          mode: 'cors',
          method: 'POST',
          headers: {
            'Authorization': Variables.authorization,
          },
          body: JSON.stringify(selectedAns),
          
        }).then(results => {
          return results.json();
        }).then(data => {
          
        })
    }

    updateCountDown = (timeLeft) => {
      this.setState({timeLeft: timeLeft});

      let dataOptions = Variables.timeRewardPair;

        var timeUsed = Variables.gameTime - timeLeft;
        dataOptions.map((data) => {
          if (this.isBetween(timeUsed, data.min_time, data.max_time)) { 
            this.setState({dataToGet: data.credit_value});
            return true;
          }

          Variables.potentialData = data.data
          Variables.potentialCredit = `${data.credit_value} MT`
          Variables.potentialTime = timeUsed - data.max_time;

          return false;
        });

        if(timeLeft <= 0){
          Variables.currentSelection = "";
          this.endGame();
        }
    }

    startCountDown = () => {
        //this.intervalHandle = setInterval(this.beginCountDown, 1000);
        this.startWorker()
    }

    isBetween(n, a, b) {
      return (n - a) * (n - b) <= 0
    }

   RemoveFadeOut = (e) =>{
    this.setState({canAnswer: true})

     let ele = e.target;
     ele.classList.remove("fadeoutanime");
    void ele.offsetWidth;

    let newIndex = this.state.questionIndex + 1;
      if(newIndex >= this.state.questions.length){
            this.endGameSuccess();
      }else{
            this.setState({questionIndex: newIndex});
            Variables.questionIndex = newIndex;
            this.downloadImagesForQuestion();
      }

   }

   pauseCountDown(){
     Variables.pauseCountDown = true;
   }

  continueCountDown(){
    Variables.pauseCountDown = false;
  }

  downloadImagesForQuestion(){
      this.pauseCountDown();
      let question = Variables.questions[Variables.questionIndex].question;
      var matches = question.match(/\bhttps?:\/\/\S+/gi);
      matches = (matches == null) ? [] : matches;
      this.setState({
        "imgQuestion": (matches.length > 0) ? matches[0] : "",
        "textQuestion": (matches.length > 0) ? question.replace(matches[0], '') : question,
      });
      if(matches.length > 0){
        let thisObj = this;
        setTimeout(this.IsImageOk, 500, thisObj);
      }else{
        this.continueCountDown();
      }
  }

  shuffle(array) {
    array.sort(() => Math.random() - 0.5);
    return array;
  }

  componentDidMount() {
      if(Variables.questions.length > 0){
        this.setState({questions: Variables.questions});
        this.downloadImagesForQuestion();
        this.setState({canAnswer: true});
        this.startCountDown();

        let answerObj = {};
        for (let d of Variables.questions) {
          let questionId = d.id;
          answerObj[questionId] = null;
          
          //shuffle options
          if(d.optionC != null && d.optionD != null && d.optionC.length > 0 && d.optionD.length > 0){
            let arr = [d.optionA, d.optionB, d.optionC, d.optionD];
            arr = this.shuffle(arr);

            d.optionA = arr[0];
            d.optionB = arr[1];
            d.optionC = arr[2];
            d.optionD = arr[3];
          }
        }
        this.setState({
          selectedAns: answerObj
        });
      }
  }

  IsImageOk(thisObj) {
    let img = document.getElementById("img-question");
    if (!img.complete || img.naturalWidth === 0) {
      setTimeout(thisObj.IsImageOk, 500, thisObj);
      return;
    }

    thisObj.continueCountDown();
  }


  render() {
    if(this.state.questions.length === 0 || !this.state.canAnswer)
        return false;

    return (
      <div>
        <Greeny />
        <Processing visible={this.state.processing} />
        <div id="questionSection" className="mt50"
          onAnimationEndCapture={this.RemoveFadeOut}>
          <div className="center-div ">
            <div className="circleBase type1 center-div"> <span>{this.state.timeLeft}</span> </div>
            <div className="white text-center mt10 l-s-1">{this.state.dataToGet} MT</div>
          </div>
          <h2 className="center-div mt40 white max400">
              {this.state.textQuestion}
              {this.state.imgQuestion.length > 0 && 
              <div className="white-bg">
                <img alt="" id="img-question" src={this.state.imgQuestion} />
              </div>
              }
          </h2>
          <div className="center-div mt40">
            <div className="startPlaying option margin-auto" onClick={this.NextQuestion}>
              {this.state.questions[this.state.questionIndex].optionA}
            </div>
          </div>
          <div className="center-div mt10">
            <div className="startPlaying option margin-auto" onClick={this.NextQuestion}>
              {this.state.questions[this.state.questionIndex].optionB}
            </div>
          </div>
          {this.state.questions[this.state.questionIndex].optionC.length > 0 &&
              <div className="center-div mt10">
              <div className="startPlaying option margin-auto" onClick={this.NextQuestion}>
              {this.state.questions[this.state.questionIndex].optionC}
            </div>
              </div>
          }
          {this.state.questions[this.state.questionIndex].optionD.length > 0 &&
          <div className="center-div mt10">
            <div className="startPlaying option margin-auto" onClick={this.NextQuestion}>
              {this.state.questions[this.state.questionIndex].optionD}
            </div>
          </div>
          }
          </div>
      </div>
    )
  }
}

export default Question
