import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Downloading questions": "Downloading questions...",
      "Ready": "Ready!!!",
      "Congratulations": "Congratulations!",
      "You've won": "You've won",
      "You nearly won": "You were {{seconds}} from winning {{credit}}",
      "Come back tomorrow": "Come back tomorrow for more",
      "Wrong question": "Oops! You got the {{position}} question wrong.",
      "Ran out" : "Oops! You ran out of time.",
      "Used up today's chances": "You've used up your chances for today, Try again at {{charge}} now! <strong>The Quicker you answer, The bigger the reward!</strong>",
      "Exhausted chances": "You've exhausted all your chances for today, Come back tomorrow for another chance.",
      "Play again": "Play Again",
      "Try again": "Try Again",
      "Play now": "Play Now",
      "Processing": "Processing...",
      "Loading game": "Loading Game...",
      "Leave Triv5":"Leave Triv5",
      "first": "1st",
      "second": "2nd",
      "third": "3rd",
      "fourth": "4th",
      "fifth": "5th",
      "Share message": "I just won {{won}} MT credits on Brainwave. Win yours too by playing Brainwave at"
    }
  },
  pt: {
    translation: {
      "Downloading questions": "Baixando perguntas...",
      "Ready": "Preparar!!!",
      "Congratulations": "Parabéns!",
      "You've won": "Ganhaste",
      "You nearly won": "Estavas a {{seconds}} segundos de ganhar o {{credit}}",
      "Come back tomorrow": "Volte amanhã para mais",
      "Wrong question": "Você errou a {{position}} questão.",
      "Ran out" : "Acabou seu tempo.",
      "Used up today's chances": "Você esgotou suas chances de hoje, tente novamente por {{charge}}! <strong>Quanto mais rápido você responder, maior será a recompensa</strong>",
      "Exhausted chances": "Esgotaste todas as tuas chances de hoje, volte amanhã para outra chance.",
      "Play again": "Tente novamente",
      "Try again": "Tente novamente",
      "Play now": "Jogue agora",
      "Processing": "Processamento...",
      "Loading game": "Carregando o jogo...",
      "Leave Triv5":"Sair do Triv5",
      "first": "primeira",
      "second": "segunda",
      "third": "terceira",
      "fourth": "quarta",
      "fifth": "quinta",
      "Share message": "Acabei de ganhar {{won}} créditos MT no Brainwave. Ganhe o seu também jogando Brainwave em"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;