import React from 'react'
import {
    AwesomeButton,
  } from 'react-awesome-button';
  import  'react-awesome-button/dist/themes/theme-rickiest.css';

function InvalidCaptcha() {
        return (
            <div className="center-div">
              <div>
                <div className="circleBase type2 center-div">  
                    <div className="clear-fix mt20 h20">
                    </div>

                    <div className="clear-fix inline">
                        <div className="blink2 eye2 float-left"></div>
                        <div className="blink2 eye2 float-left ml20"></div>
                    </div>
                </div>
                <div className="center-div mt-40">
                        <div className="mouth2"></div>
                </div>
              </div>
    
              <div className="center-div max400">
                <h2 className="white mt0">Ooops! We ran into a minor glitch</h2>
                <p className="white mt20">Kindly reach us on our social pages</p>

                <AwesomeButton className="startPlaying option margin-auto" type="secondary" 
                    href="https://facebook.com/brainwave.gh" target="_blank">
                    <span> Facebook</span>
                </AwesomeButton>

                <div className="text16"></div>
                <AwesomeButton className="startPlaying option margin-auto" type="secondary" 
                            href="https://instagram.com/brainwave.gh" target="_blank">
                            <span>Instagram</span>
                </AwesomeButton>
              </div>

            </div>
          )
}

export default InvalidCaptcha
