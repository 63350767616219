import React from 'react'
import Greeny from './Greeny';
import { Trans } from 'react-i18next'

function Processing(props) {

  if(!props.visible)
    return null;
    
  return (
    <div>
      <Greeny />
      <div className="processingDiv">
        <div id="content">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <h3 className="white mt20"><Trans>Processing</Trans></h3>
        </div>
      </div>
    </div>
  )
}

export default Processing
