// var environment = "dev"; //dev | prod
var environment = 'prod' //dev | prod
var Variables = {
    doubleWinText1: 'What is better than winning airtime? Winning for two!',
    doubleWinText2: 'Answer 5 questions correctly to win airtime instantly for you and your special person',
    doubleWinText3: 'Your special person\'s number here',
    doubleWinError1: 'Sorry, You can not share with yourself',
    doubleWinError2: 'Oops, you have already shared airtime with this number',
    doubleWinAlreadySharedWith: [],
    isDoubleWin: false,
    friendsNumber: '',
    showGreeny: false,
    countryCode: "258",
    serviceName: "Brainwave",
    cookieIdentityCount: 2,
    timeLeft: 0,
    showAnswer: 0,
    questionLimit: 5,
    gameTime: 50,
    timeTaken: 0,
    playCount: 0,
    maxPlayCount: 1,
    maxPlayTrial: 3,
    savitar: "",
    network: "vodacom",
    mobileNo: "",
    globalToken: "",
    answer: "",
    potentialData: "1GB",
    potentialCredit: "5 MT",
    potentialTime: 2,
    networks: ["vodacom"],
    mobileNoLength: 10,
    billmeAmount: "50 MT",
    billmeChances: 2,
    wrongQuestionPosition: "3rd",
    currentSelection: "",
    authorization: (environment === "dev") ? "Basic Ynd2bXpiOnN5ISFAOHNpa3NrZA==" : "Basic Ynd2bXpiOnN5ISFAOHNpa3NrZA==",
    captchaSiteKey: '6LcDsKUUAAAAABCrYr8ZI-EXyLl0rouDzi2tkMcv',
    timeRewardPair: [],

    selectedAns: [],
    questions: [],
    questionIndex: 0,
    pauseCountDown: false,
    totalBrainwavePoint: 0,
    winningAmount: "GHs 2,500",
    slackBotApi: "https://hooks.slack.com/services/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",
    apiBaseUrl: (environment === "dev") 
        ? "https://api.brainwavemz.com/api/triv5/" 
        : "https://api.brainwavemz.com/api/triv5/",
    subscriptionApiBase: "https://apps.mobivs.com/services/BRAINWAVE/APPS/public/api/v1/",
    shareUrl: (message) => `whatsapp://send?text=${encodeURIComponent(message)}%20https%3A%2F%2Fplay.brainwavemz.com`,
    //shareUrl: "https://wa.me/?text=https%3A%2F%2Ftriv5.brainwavegh.com%20Play%20Triv5%20and%20win%20up%20to%201GB%20data%20or%20Ghs5%20credit.",
    facebookShareUrl: "https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent("https://play.brainwavemz.com"),
    twitterShareUrl: (message) => `https://twitter.com/share?text=${message}&url=https://play.brainwavemz.com`,
    billmeUrl: "https://api.brainwavemz.com/api/v1/",
    paymentFakeUrl: "https://triv5-payment.brainwavegh.com",
    insufficientFunds: false,
};
export default Variables;