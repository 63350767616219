import React, { Component } from 'react'
import {
    AwesomeButton,
  } from 'react-awesome-button';
  import Header from './Header';
  import  'react-awesome-button/dist/themes/theme-rickiest.css';
  import SubscribePopup from "./PopupSubscribe";
  import SubSentPopup from "./SubscriptionSent";
  import LimitReachedPopup from "./LimitReachedPopup";
  import Variables from "./Variables";
  import Processing from "./Processing";
import Footer from './Footer';
import cookie from 'react-cookies'

export class EnterMobileNo extends Component {
    constructor(props){
        super(props);
        this.state = {
            processing: false,
            isProcessed: false,
            popup: 0
        };
    }
    componentDidMount = (e) => { 
        let tel = document.getElementById("txTel");
        tel.value = Variables.mobileNo;
        tel.focus();
    }
    GetNumber = (e) => {
        Variables.mobileNo = e.target.value;
    }
    GenerateToken(){
        var mobileNo = '%2b' + Variables.countryCode + Variables.mobileNo.substr(Variables.mobileNo.length - 9);
        
        fetch(Variables.apiBaseUrl + 'genToken/' + mobileNo, {mode: 'cors'})
        .then(results => {
            return results.json();
        }).then(data => {
            //ga('set', 'userId', 'USER_ID');
            if(data.hasOwnProperty('success')){
                Variables.token = data.token;
                Variables.playCount = parseInt(data.lives);
                Variables.maxPlayCount = parseInt(data.max_lives);
                this.props.onChange(3);
            }else{
                Variables.playCount = 0;
                Variables.maxPlayCount = 0;
                if(data.hasOwnProperty('max_lives')){
                    Variables.playCount = parseInt(data.lives);
                    Variables.maxPlayCount = parseInt(data.max_lives);
                }
                this.props.onChange(10);
            }
        })
    }
    SaveMobileNo = (event) => {
        if(Variables.mobileNo.length === Variables.mobileNoLength && Variables.network !== ""){
            this.setState({
                processing: true,
            });
            var maxAge = 60 * 1000 * 24 * 30 //cookie max age set to a month
            cookie.save('bw_user', Variables.mobileNo, { path: '/', maxAge: maxAge });
            var mobileNo = '%2b' + Variables.countryCode + Variables.mobileNo.substr(Variables.mobileNo.length - 9);
            var url = Variables.subscriptionApiBase + 'getSubStatus/' + mobileNo
            fetch(url, {mode: 'cors'})
                .then(results => {
                return results.json();
                }).then(data => {
                    this.setState({
                        isProcessed: true,
                    });
                    
                    setTimeout(() => {
                        this.setState({
                            processing: false
                        });
                    }, 1000);
                    
                    if(data.status){
                        this.GenerateToken();
                    }else{
                        //subscribe user
                        this.setState({
                            popup: 1
                        });
                    }
                })
        }
    }
    PreventPasting = (event) => {
        event.preventDefault();
    }
    ValidateMobileNo = (event) => {
        let key = event.key;
        
        if(key === " "){
            event.preventDefault();
        }
        if(key === "Enter" || key === "Backspace" || !isNaN(key)){
            return true;
        }else{
            event.preventDefault();
        }
    }
    ChangePopup = (value) => {
        this.setState({
            popup: value
        });
    }

  render() {
    return (
      <div className="fadeinanime">
          <Footer/>
        <Header />
        <div className="center-div max400 mt50">
            <h3 className="center-div mt40 white">
                Enter your mobile number to subscribe and play
            </h3>
            <div className="center-div">
                <input type="tel" autoComplete="off" className="text20 text-center" maxLength={Variables.mobileNoLength} onPaste={this.PreventPasting}
                onKeyPress={this.ValidateMobileNo} onChange={this.GetNumber} id="txTel"
                disabled = {(this.state.processing)? "disabled" : ""} />
            </div>

            <div className="center-div mt40">
            <AwesomeButton className="startPlaying" type="secondary" action={this.SaveMobileNo} 
                disabled = {(this.state.processing)? "disabled" : ""} name="tel">
                {(this.state.processing)? "Processing..." : "Play"}
            </AwesomeButton>
            </div>
            </div>

        <Processing visible={this.state.processing} />

        {this.state.popup === 1 && 
        <SubscribePopup onChange={this.ChangePopup} />
        }

        {this.state.popup === 2 && 
        <SubSentPopup onChange={this.ChangePopup} />
        }
        
        {this.state.popup === 3 && 
        <LimitReachedPopup onChange={this.ChangePopup} />
        }
      </div>
    )
  }
}

export default EnterMobileNo
