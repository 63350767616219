import React from 'react'

function Footer() {
    return (
        <div className="footer m-t-20">
            <span className="text12">Powered by </span>
            <img src="vodafone.png" alt="" className="logo-sm mb0" />
        </div>
    )
}

export default Footer
