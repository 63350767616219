import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import {
    AwesomeButton,
  } from 'react-awesome-button';
  import Variables from "./Variables";
  import  'react-awesome-button/dist/themes/theme-rickiest.css';
  import Greeny from './Greeny';
  
export class YouLost extends Component {

  state = {
      billing: false,
      error: "",
      success: "",
  };

  billMeAgain = (e) => {
    this.setState({
      billing: true
    });
    
    fetch(`${Variables.billmeUrl}billPlayer/${Variables.mobileNo}/${Variables.globalToken}/Brainwave_Triv5_Extra_0.5`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Authorization': Variables.authorization,
      },
    })
      .then(response => {
      if (response.status === 400) {
          console.log('insufficient funds');
          Variables.insufficientFunds = true;
        } else {
          Variables.insufficientFunds = false;
        }
        return response.json()
    })
    .then(data => {
      console.log(data)
      if(data.status === true) {
        window.location.reload();
      } else {
        this.setState({
          billing: false
        });
      }
    });
      // this.setState({
      //   error: "",
      //   billing: true
      // })
      // fetch(`${Variables.apiBaseUrl}billPlayerTriv5/${Variables.mobileNo}/${Variables.globalToken}`, {
      //   mode: 'cors',
      //   headers: {
      //     'Authorization': Variables.authorization,
      //   },
      // })
      // .then(results => {
      //   return results.json();
      // }).then(data => {
      //   this.setState({
      //     billing: false
      //   });
      //   if(data.hasOwnProperty('success')){
      //     this.setState({
      //       success: data.success
      //     })
      //     setTimeout(() => {
      //       if(window.location.href.indexOf("MSISDN") > 0){
      //         window.location.reload()
      //       }else{
      //         window.location.href = window.location.href + "?MSISDN="+Variables.mobileNo+"&TOKEN="+Variables.globalToken;
      //       }
      //     }, 2000)
      //   }else{
      //     this.setState({
      //       error: data.error
      //     })
      //   }
      // })
  }

  PlayAgain = (e) => {
      this.setState({
        billing: true
      })
      fetch(Variables.apiBaseUrl + 'triv5GenToken/' + Variables.mobileNo + "/" + Variables.globalToken, {
        mode: 'cors',
        headers: {
          'Authorization': Variables.authorization,
        },
      })
      .then(results => {
        return results.json();
      }).then(data => {
        if(data.hasOwnProperty('success')){
          Variables.token = data.token;
          Variables.playCount = parseInt(data.lives);
          Variables.maxPlayCount = parseInt(data.max_lives);
          this.props.onChange(3);
        }else{
          this.setState({
            popup: 3
          });
        }
      })
  }

  RestartEyeBlinking = (e) =>{
      var rightTearDrop = document.getElementsByClassName("teardrop1");
      for (let drop of rightTearDrop) {
          drop.classList.remove("teardrop1");
          void drop.offsetWidth;
          drop.classList.add("teardrop1");
      }

      rightTearDrop = document.getElementsByClassName("teardrop2");
      for (let drop of rightTearDrop) {
          drop.classList.remove("teardrop2");
          void drop.offsetWidth;
          drop.classList.add("teardrop2");
      }

      rightTearDrop = document.getElementsByClassName("teardrop3");
      for (let drop of rightTearDrop) {
          drop.classList.remove("teardrop3");
          void drop.offsetWidth;
          drop.classList.add("teardrop3");
      }

      var eyes = document.getElementsByClassName("blink2");
      for (let eye of eyes) {
          eye.classList.remove("blink2");
          void eye.offsetWidth;
          eye.classList.add("blink2");
      }
  }

  render() {
    return (
      <div className="center-div max400">
        <div>
        <Greeny />
          <div className="circleBase type2 center-div mt50">  
              <div className="clear-fix inline mt25">
                  <div className="blink2 eye float-left" onAnimationEndCapture={this.RestartEyeBlinking}></div>
                  <div className="blink2 eye float-left ml20"></div>
              </div>
              <div className="clear-fix pl25 pr25 mt-5">
                  <div className="fade teardrop1 arrow-up float-left"></div>
                  <div className="fade teardrop1 arrow-up float-left ml35"></div>
              </div>
              <div className="clear-fix pl25 pr25 mt-5">
                  <div className="fade teardrop2 arrow-up float-left"></div>
                  <div className="fade teardrop3 arrow-up float-left ml35"></div>
              </div>
          </div>
          <div className="center-div mt-40">
                  <div className="mouth"></div>
          </div>
        </div>

        <div className="center-div max400">
          {Variables.currentSelection !== "" && 
            <h2 className="white mt50"><Trans values={{
              position:Variables.wrongQuestionPosition
            }}>Wrong question</Trans></h2>
          }
          {Variables.currentSelection === "" && 
            <h2 className="white mt50"><Trans>Ran out</Trans></h2>
          }
          {Variables.showAnswer === 1 && 
          <div className="center-div">
            <h2 className="white mt0">Correct answer is "<b><u>{Variables.answer}</u></b>"</h2>
          </div>
          }

          {Variables.playCount === 1 && Variables.maxPlayCount < Variables.maxPlayTrial && 
            <p className="white mt20 text22">
              <Trans values={{
                charge:"0.5 MT"
              }}>Used up today's chances</Trans>
            </p>
            
            
          }
          {Variables.insufficientFunds && <>Você tem créditos insuficientes. Recarregue e tente novamente.</>}


          {Variables.maxPlayCount >= Variables.maxPlayTrial &&
            <p className="white mt50 text22"><Trans>Exhausted chances</Trans></p>
          }

        </div>

        {Variables.playCount > 1 && 
        <>
        {!this.state.billing && 
            <div className="startPlaying option margin-auto" onClick={this.PlayAgain}>
            <Trans>Try again</Trans>
            </div>
          }

          {this.state.billing && 
            <div className="startPlaying option margin-auto" onClick={this.billMeAgain}>
            <Trans>Processing</Trans>
            </div>
          }
        </>
        }
        
        {Variables.playCount === 1 && Variables.maxPlayCount < Variables.maxPlayTrial && 
        <>
        {!this.state.billing && this.state.success === "" && 
            <AwesomeButton className="startPlaying option margin-auto" 
                type="secondary" action={this.billMeAgain}>
                <Trans>Play again</Trans>
            </AwesomeButton>
          }

          {this.state.billing && this.state.success === "" && 
            <AwesomeButton className="startPlaying option margin-auto" 
              disabled type="secondary" >
              <Trans>Processing</Trans>
            </AwesomeButton>
          }

          {this.state.success !== "" && 
          <AwesomeButton className="startPlaying option margin-auto" 
            disabled type="secondary" >
            <Trans>Loading game</Trans>
          </AwesomeButton>
          }
        </>
        }

        {this.state.error !== "" && 
          <p className="error-msg mt20">{this.state.error}</p>
        }
        {this.state.success !== "" && 
          <p className="success-msg mt20">{this.state.success}</p>
        }
      </div>
    )
  }
}

export default YouLost
