import React from 'react'
import Variables from "./Variables";

function Greeny() {
    return (
        <>
            {Variables.Greeny && 
                <div className="greeny">
                    <img src="/greeny.png" alt="" />
                </div>
            }
        </>
    )
}

export default Greeny