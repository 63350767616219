import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Confetti from 'react-confetti'
import { AwesomeButton } from 'react-awesome-button';
import Variables from "./Variables";
import  'react-awesome-button/dist/themes/theme-rickiest.css';
import WhatsAppIcon from '../images/whatsapp2.png'
import Greeny from './Greeny';

function YouWon() {
    const { t } = useTranslation();

    return (
      <div>
        <Greeny />
        <div className="center-div max400 mt50">
          <p className="white text30 mb0"><Trans>Congratulations</Trans></p>
          <p className="white text30 mb0 mt0"><Trans>You've won</Trans> {Variables.dataWon} 👍</p>
          
          {Variables.potentialTime === 1 &&
          <p className="white text22 mt20">
            <Trans values={{
              seconds: "a",
              credit: Variables.potentialCredit
            }}>You nearly won</Trans>
          </p>
          }
          
          {Variables.potentialTime > 1 &&
          <p className="white text22 mt10">
            <Trans values={{
              credit:Variables.potentialCredit,
              seconds:Variables.potentialTime
            }}>You nearly won</Trans>
          </p>
          }

          <p className="white text22 mt10">
            <Trans>Come back tomorrow</Trans>
          </p>
          
          <div style={{zIndex: '9000', position: 'relative'}}>
          <AwesomeButton 
            className="startPlaying option margin-auto" style={{marginBottom:'8px'}} type="secondary" 
            href={Variables.shareUrl(t("Share message", {
              won: Variables.dataWon
            }))}
            target="_blank"
          >
            <img alt="Whatsapp" src={WhatsAppIcon} className="iconImg" />
            <span>Share on Whatsapp</span>
          </AwesomeButton>

          <AwesomeButton style={{marginBottom:'8px'}} className="startPlaying option margin-auto" type="secondary" 
                    href={Variables.facebookShareUrl} target="_blank">
                    <span>Share on Facebook</span>
          </AwesomeButton>

          <AwesomeButton className="startPlaying option margin-auto" type="secondary" 
                    href={Variables.twitterShareUrl(t("Share message", {
                      won: Variables.dataWon
                    }))} target="_blank">
                    <span>Share on Twitter</span>
          </AwesomeButton>
          </div>
        </div>
        <Confetti />
      </div>
    )
}

export default YouWon
