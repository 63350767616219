import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import { withTranslation } from 'react-i18next';
import {
    AwesomeButton,
  } from 'react-awesome-button';
  import Variables from "./Variables";
  import  'react-awesome-button/dist/themes/theme-rickiest.css';
import Header from './Header';
import Greeny from './Greeny';
import {BW_URL} from '../utils/constants'
  
export class Billme extends Component {
  state = {
    billing: false,
    error: "",
    success: ""
  }
  billMeAgain = (e) => {
    this.setState({
      billing: true
    });

    fetch(`${Variables.billmeUrl}billPlayer/${Variables.mobileNo}/${Variables.globalToken}/Brainwave_Daily_5_mod`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Authorization': Variables.authorization,
      },
    })
      .then(response => {
        if (response.status === 400) {
          console.log('insufficient funds');
          Variables.insufficientFunds = true;
        } else {
          Variables.insufficientFunds = false;
        }
        return response.json()
      })
      .then(data => {
        console.log(data);
      if(data.status === true) {
        window.location.reload();
      } else {
        this.setState({
          billing: false
        });
      }
    }).catch(err => {
      console.log(err);
      console.log('data')
    });

    // document.location = Variables.paymentFakeUrl;
      // this.setState({
      //   error: ""
      // })
      // this.setState({
      //   billing: true
      // });
      // fetch(Variables.apiBaseUrl + 'billPlayerTriv5/' + Variables.mobileNo + "/" + Variables.globalToken, {
      //   mode: 'cors',
      //   headers: {
      //     'Authorization': Variables.authorization,
      //   },
      // }).then(results => {
      //       return results.json();
      //   }).then(data => {
      //     this.setState({
      //       billing: false
      //     });
      //     if(data.hasOwnProperty('success')){
      //       this.setState({
      //         success: data.success
      //       })
      //       setTimeout(() => {
      //         if(window.location.href.indexOf("MSISDN") > 0){
      //           window.location.reload()
      //         }else{
      //           window.location.href = window.location.href + "?MSISDN="+Variables.mobileNo+"&TOKEN="+Variables.globalToken;
      //         }
      //       }, 2000)
      //     }else{
      //       this.setState({
      //         error: data.error
      //       })
      //     }
      //   })
  }

  render() {
    if (Variables.maxPlayCount === 0) { window.location.href = BW_URL}
    
    return (
      <div className="fadeinanime">
        <Greeny />
        <Header />
        <div className="center-div max400">   
          {(Variables.playCount === 1 || Variables.playCount === 0) && (Variables.maxPlayCount > 0 && Variables.maxPlayCount < Variables.maxPlayTrial) && 
            <h3 className="white mt100 text22">
              {/* Você esgotou suas chances de hoje, tente novamente por 0.5 MT. <b>Quanto mais rápido você responder, maior será a recompensa</b> */}
              <Trans values={{charge: '5 MT'}}>Used up today's chances</Trans> 
            </h3>
          }
          {Variables.insufficientFunds && <>Você tem créditos insuficientes. Recarregue e tente novamente.</>}
          {Variables.maxPlayCount >= Variables.maxPlayTrial &&
            <h3 className="white mt100 text22">
              <Trans>Exhausted chances</Trans>
            </h3>
          }
          {Variables.maxPlayCount === 0 &&
            <h3 className="white mt100 text22">Get chances at {Variables.billmeAmount} now!!!</h3>
          }

          {(Variables.playCount === 1 || Variables.playCount === 0) && (Variables.maxPlayCount > 0 && Variables.maxPlayCount < Variables.maxPlayTrial) && 
          <>
          {!this.state.billing && 
            <div className="startPlaying option margin-auto" onClick={this.billMeAgain}>
              <Trans>Try again</Trans>
            </div>
          }

          {this.state.billing && 
            <div className="startPlaying option margin-auto">
            <Trans>Processing</Trans>
            </div>
          }
          </>
          }

          {Variables.maxPlayCount === 0 &&
          <>
          {!this.state.billing && this.state.success === "" && 
            <AwesomeButton className="startPlaying option margin-auto" 
              type="secondary" action={this.billMeAgain}>
              <Trans>Play now</Trans>
            </AwesomeButton>
          }

          {this.state.billing && this.state.success === "" && 
            <AwesomeButton className="startPlaying option margin-auto" 
              disabled type="secondary" >
              <Trans>Processing</Trans>
            </AwesomeButton>
          }

          {this.state.success !== "" && 
            <AwesomeButton className="startPlaying option margin-auto" 
              disabled type="secondary" >
              <Trans>Loading game</Trans>
            </AwesomeButton>
          }
          </>
          }

          {this.state.error !== "" && 
            <p className="error-msg mt20">{this.state.error}</p>
          }
          {this.state.success !== "" && 
            <p className="success-msg mt20">{this.state.success}</p>
          }
        </div>

        <div className="Exit" style={{display: 'flex', justifyContent:'center', marginTop: '20px'}}>
          <a href={BW_URL}>
            <AwesomeButton className="aws-btn__content ml10 inline-btn" type="secondary">
              <Trans>Leave Triv5</Trans>
            </AwesomeButton>
          </a>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Billme)
